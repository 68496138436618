import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Terms = () => (
  <Layout>
    <SEO title="Terms of Service" />
    <div style={{ padding: 100 }}>
      <h1>Terms of Service</h1>‍
      <p>
        These Terms of Service are a contract between you and Quantifai, Inc. By
        using the Quantifai website (“Site”) and any services accessible from
        the Quantifai Site (collectively, the "Services"), you are agreeing to
        be bound by the following Terms of Service. If a user violates any of
        the terms outlined below, we reserve the right to cancel accounts or bar
        access to accounts without notice. If you do not agree to these terms,
        please do not use our Services.
      </p>
      <h2>Your Account</h2>‍
      <p>
        You must be a human. Accounts registered by "bots" or other automated
        methods are not permitted. You must be 13 years of age or older. You
        must provide a valid email address, along with any other information
        required by Quantifai during the registration process. You are
        responsible for maintaining the security of your account and password.
        Quantifai will not be liable for any loss or damage from your failure to
        comply with this security obligation. Personally identifiable
        information submitted by you shall be subject to our Privacy Policy. One
        person or legal entity may not maintain more than one free account. You
        may not use the Quantifai Site for any illegal or unauthorized purpose.
        You must not, in the use of the Quantifai Site, violate any laws in your
        jurisdiction (including but not limited to copyright laws).
      </p>
      <h2>Payments, Refunds, Upgrading and Downgrading</h2>‍
      <p>
        Unless otherwise arranged with an Quantifai representative, a valid
        credit card is required for paying accounts. Free accounts are not
        required to provide a credit card number. The Quantifai Service is
        billed in advance in accordance with our pricing schedule and all
        monthly and annual payments are nonrefundable. There will be no refunds
        or credits for partial periods of service, upgrade/downgrade refunds, or
        refunds for service periods unused with an open account. In order to
        treat everyone equally, no exceptions will be made. All fees are
        exclusive of all taxes, levies, or duties imposed by taxing authorities,
        and you shall be responsible for payment of all such taxes, levies, or
        duties, excluding only United States (federal or state) taxes. You agree
        to pay for any such taxes that might be applicable to your use of the
        Quantifai Site and payments made by you herein.
      </p>
      <h2>Violation of these Terms of Service</h2>‍
      <p>
        Quantifai reserves the right to investigate and prosecute violations of
        any of these Terms of Service to the fullest extent of the law.
        Quantifai may involve and cooperate with law enforcement authorities in
        prosecuting users who violate the Terms of Service. You acknowledge that
        Quantifai has no obligation to prescreen or monitor your access to or
        use of the Quantifai Site or any information, materials or other content
        provided or made available through the Quantifai Site, but has the right
        to do so. You hereby agree that Quantifai may, in the exercise of
        Quantifai' sole discretion, remove or delete any entries, information,
        materials or other content that violates these Terms of Service or that
        is otherwise objectionable.
      </p>
      <h2>Cancellation</h2>‍
      <p>
        Customer is solely responsible for properly notifying Quantifai of its
        election not to have this Agreement automatically renew by following the
        cancellation directions available in Customer’s Quantifai account. Any
        cancellation of your Account will result in the deactivation or deletion
        of your Account or your access to your Account, and the forfeiture and
        relinquishment of all Content in your Account. This information cannot
        be recovered from Quantifai once your account is canceled. Please be
        aware that Quantifai may for a time retain residual information in our
        backup and/or archival copies of our database. We will make reasonable
        commercial efforts to delete your information as soon as possible after
        you communicate that intention to us. Cancellations will take effect at
        the end of your current service period. Upon the commencement of a new
        service period, the Quantifai Site will terminate without additional
        notice, and you will not be charged for any subsequent service periods.
        You will not be provided any refunds for unused time on your service
        period.
      </p>
      <h2>Termination</h2>‍
      <p>
        Quantifai reserves the right to terminate any free Accounts that do not
        have any activity for sixty (60) days or trial accounts without a
        payment method thirty (30) days after expiration. Quantifai, in its sole
        discretion, has the right to suspend or terminate your Account if you
        breach these Terms of Service. Quantifai may refuse to provide you any
        current or future use of the Quantifai Site, or any other Quantifai
        service. Any termination of your Account will result in the deactivation
        or deletion of your Account, denied access to your Account, and the
        forfeiture and relinquishment of all Content in your Account. This
        information cannot be recovered from Quantifai once your Account is
        terminated; however Quantifai may for a time retain residual information
        in our backup and/or archival copies of our database.
      </p>
      <h2>Modifications to the Quantifai Site and Prices</h2>‍
      <p>
        Quantifai reserves the right at any time and from time to time to modify
        or discontinue, temporarily or permanently, the Quantifai Site (or any
        part thereof) with or without notice. Prices of all Quantifai plans,
        including but not limited to monthly subscription plan fees to the
        Quantifai Site, are subject to change. Quantifai shall not be liable to
        you or to any third party for any modification, price change, suspension
        or discontinuance of the Quantifai Services. We reserve the right, in
        our sole discretion, to change, modify, add to, supplement or delete any
        portion of these Terms of Service at any time, effective with or without
        prior notice; provided, however, that we will use reasonable efforts to
        provide you with notification of any material changes (as determined in
        our sole discretion) by email. If any future changes to these Terms of
        Service are unacceptable to you or cause you to no longer be in
        compliance, you must immediately stop using the Quantifai Service. Your
        continued use of the Quantifai Service following any revision to this
        Agreement constitutes your complete acceptance of any and all such
        changes. Any new features that augment or enhance the current Quantifai
        Service, including the release of new tools and resources, will be
        subject to these Terms of Service.
      </p>
      <h2>Copyright and Content Ownership</h2>‍
      <p>
        We claim no intellectual property rights over the content submitted or
        created exclusively in your Quantifai account. Any content that is yours
        remains yours. These Terms do not grant us any licenses or rights to
        your content except for the limited rights needed for us to provide the
        Services to you. The look and feel of the Quantifai Site is © 2019
        Quantifai, Inc. all rights reserved. You may not duplicate, copy, or
        reuse any portion of the code or visual design elements without express
        written permission.
      </p>
      Indemnification ‍
      <p>
        You agree to indemnify and hold Quantifai, its parents, subsidiaries,
        affiliates, officers, partners and employees, harmless from any claim or
        demand, including reasonable attorneys' fees, made by any third party
        due to or arising out of your use of the Services, use of your account
        by any third party, the violation of the terms of use by you, or the
        infringement by you, or any third party using your account, of any
        intellectual property or other right of any person or entity.
      </p>
      <h2>Limitation of Liability</h2>‍
      <p>
        UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL
        QUANTIFAI OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR
        THIRD-PARTY PARTNERS OR SUPPLIERS, BE LIABLE TO YOU FOR ANY LOST PROFITS
        OR SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
        THAT RESULT FROM OR RELATE TO THE SERVICES, INCLUDING YOUR USE THEREOF,
        OR ANY OTHER INTERACTIONS WITH QUANTIFAI, EVEN IF Quantifai OR A
        QUANTIFAI AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES. IN NO EVENT SHALL QUANTIFAI OR ITS AFFILIATES,
        CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD-PARTY PARTNERS OR SUPPLIERS’
        TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
        ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE SITE OR
        SERVICES EXCEED EITHER THE AMOUNT OF FEES PAID BY YOU TO QUANTIFAI FOR
        THE SERVICES IN THE SIX (6) MONTH PERIOD PRECEDING THE EVENTS THAT GIVE
        RISE TO THE APPLICABLE CLAIM OR FIFTY U.S. DOLLARS, WHICHEVER IS
        GREATER.
      </p>
      <h2>Disclaimer</h2>‍
      <p>
        THE SERVICES, Quantifai SITE AND ITS CONTENTS ARE PROVIDED "AS IS" AND
        "AS AVAILABLE" WITHOUT ANY WARRANTY OR REPRESENTATIONS OF ANY KIND,
        WHETHER EXPRESSED OR IMPLIED. QUANTIFAI IS A DISTRIBUTOR AND NOT A
        PUBLISHER OF THE CONTENT SUPPLIED BY THIRD PARTIES; AS SUCH, QUANTIFAI
        EXERCISES NO EDITORIAL CONTROL OVER SUCH CONTENT AND MAKES NO WARRANTY
        OR REPRESENTATION AS TO THE ACCURACY, RELIABILITY OR CURRENCY OF ANY
        INFORMATION, CONTENT, SERVICE OR MERCHANDISE PROVIDED THROUGH OR
        ACCESSIBLE VIA THE SITE. WITHOUT LIMITING THE FOREGOING, QUANTIFAI
        SPECIFICALLY DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS AS THE
        PUBLISHER OF ANY CONTENT TRANSMITTED ON OR IN CONNECTION WITH THE SITE
        OR ON SITES THAT MAY APPEAR AS LINKS ON THE SITE, OR THE SERVICES,
        INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. NO
        ORAL ADVICE OR WRITTEN INFORMATION GIVEN BY QUANTIFAI NOR ANY OF ITS
        AFFILIATES, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, OR THE LIKE SHALL
        CREATE A WARRANTY. PRICE AND AVAILABILITY INFORMATION IS SUBJECT TO
        CHANGE WITHOUT NOTICE.
      </p>
      <h2>Publicity</h2>‍
      <p>
        You grant Quantifai the right to use your company name and logo as a
        reference for marketing or promotional purposes on our website and in
        other public or private communications, subject to your standard
        trademark usage guidelines as provided to us from time-to-time. If you
        don’t want to be listed, you may send an email to hello@quantif.ai
        declining to be used as a reference.
      </p>
      <h2>Jurisdiction</h2>‍
      <p>
        These Terms of Service will be governed by and construed in accordance
        with the laws of the State of Delaware, without giving effect to its
        conflict of law provisions or your actual state or country of residence.
        If for any reason a court of competent jurisdiction finds any provision
        or portion of the Terms of Service to be unenforceable, the remainder of
        the Terms of Service will continue in full force and effect. Any waiver
        of any provision of the Terms of Service will be effective only if in
        writing and signed by an authorized representative of Quantifai.
      </p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
);

export default Terms;
